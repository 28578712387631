.paymentRoot{
  max-width: 500;
  margin: 35vh auto;
}

.paymentContent{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.btnContainer{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
}

.paymentBtn{
  margin: '2em auto 1em';
}
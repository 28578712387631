.notFoundContainer {
    background-color: #1f2129;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.notFoundText {
    color: #0D9FFF;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 20px;
}
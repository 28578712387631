.paymentCard{
    padding: 20px 32px 20px;
    display: flex;
    flex-direction: column;
}

.paymentCardItem{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.paymentCardInner{
    padding-bottom: 20px;
    border-bottom: 1px solid #303030;
}

.viewInvoiceCta{
    font-size: 16px;
    color: #0D9FFF;
    cursor: pointer;
    font-weight: bold;
}

.viewInvoiceCtaGroup{
    display: flex;
    grid-column-gap: 10px;
}


@media only screen and (max-width: 767px) {
    .paymentCard{
        padding: 16px 20px;
    }

    .viewInvoiceCta{
        font-size: 12px;
    }

    .paymentBody{
        padding-bottom: 24px;
    }
}
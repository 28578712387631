.mobileProductCardContainer{
    padding: 20px;
    background-color: #232732;
}

.mobileCardHeader{
    display: flex;
}

.mobileProductTitle{
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.mobileProductLabel{
    padding-left: 10px;
    font-size: 14px;
    color: #bdbac4;
    font-weight: 500;
}

.mobileProductInfo{
    display: flex;
    justify-content: space-between;
    padding: 2px 0px;
}

.mobileProductInfoLeft{
    color: #bdbac4;
    font-weight: 500;
}

.mobileProductInfoRight{
    font-weight: 500;
}

.mobileProductFooter{
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #0D9FFF;
    justify-content: end;
}

.detailBlueIcon{
    padding-left: 10px;
}

@media only screen and (max-width: 767px) {
    .mobileProductCardContainer{
        padding: 0px;
    }

    .mobileOrderDetailTable .ant-table-thead > tr > th,
    .mobileOrderDetailTable .ant-table-tbody > tr > td,
    .mobileOrderDetailTable .ant-table tfoot > tr > th,
    .mobileOrderDetailTable .ant-table tfoot > tr > td{
        padding: 16px 0px !important;
    }
}
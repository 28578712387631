.colorTrue{
    color: #1ec288;
}

.colorFalse{
    color: #eb3f35;
}

.avatarDetailsImg img{
    width: 80px;
    background-color: #ffffff;
    border-radius: 10px;
}
.productCard {
    display: flex;
    justify-content: space-between;
    /*box-shadow: 1px 1px 6px #ccc;*/
    background-color: #232732;
    border-radius: 15px;
    padding: 16px;
    height: 260px;
    margin: 5px;
}

.pic {
    
    
    height: 170px;
    width: 170px;
}

.properties {
    
    margin: 2px;
}

.productStore {
    font-weight: bold;
    font-size: 15px;
}

.left, .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkouts {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.checkoutNumber{
    color:#ffffff;
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 2px;
    padding-right: 5px;
}

.allCheckouts {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
}

.checkoutLabel {
    color: #bdbac4;
    font-weight: 500;
}

.successCheckout{
    color: #0D9FFF;
}

.failedCheckout{
    color: #FF9E2D;
}
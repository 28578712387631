@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
/*
body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

body {
  font-family: 'Inter', sans-serif !important;
}

#root {
  height: 100%;
  background-color: #1f2129;
  overflow-y: auto;
}

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

.ant-table-wrapper {
  height: 100%;
  overflow: hidden;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ant-table {
  max-height: 90%;
  overflow: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

body {
  font-family: 'Inter', sans-serif !important;
}

img {
  -webkit-user-drag: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-btn-primary {
  background-color: #0d9fff !important;
  border-color: #0d9fff !important;
}



@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #232732;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /*background: #000000;*/
  background: #1d2028;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Preload aniamtion */
/*
.preloader {
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  text-align: center;
}

.loading {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  height: 80px;
  margin: auto;
}

.animationLogo {
  overflow: hidden;
  vertical-align: middle;
}

.progress-wrapper {
  width: 90%;
  height: 2px;
  border-radius: 2px;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  margin: auto;
  overflow: hidden;
  margin-top: 14px;
}

.progress {
  display: block;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 200px;
  height: 2px;
  left: -102px;
  background: radial-gradient(46.66% 46.66% at 49.32% 43.12%, #00C0F3 0%, #02BBEF 15%, #07ABE4 33%, #0F92D1 53%, #1A6FB7 74%, #284296 96%, #2B3990 100%);
  -webkit-animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@-webkit-keyframes loader-anim {
  0% {
      left: -102px;
  }

  100% {
      left: 102px;
  }
}

@-moz-keyframes loader-anim {
  0% {
      left: -102px;
  }

  100% {
      left: 102px;
  }
}

@keyframes loader-anim {
  0% {
      left: -102px;
  }

  100% {
      left: 102px;
  }
}
*/
.infoContainer{
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  /*height: 100%;*/
  position: relative;
}

.infoHeader{
  font-size: 24px;
  color: #ffffff;
  padding: 32px;
  font-weight: 500;
}

.infoLabel{
  font-size: 16px;
  color: #bdbac4;
}

.infoContent{
  font-size: 16px;
  color: #ffffff;
  position: relative;
}

.infoBody{
  display: flex;
  flex-direction: column;
  padding: 0px 32px 24px;
}

.infoBodyColumn{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.infoBodyItem{
  width: 48%;
}

.statusActive{
  color: #1ec288;
  background-color: #223339;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 15px;
}

.statusDisable{
  color: #EB3F35;
  background-color: #372932;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 15px;
}

.keyCta{
  color: #0D9FFF;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}

.infoBtnContainer{
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 20px;
}

.deactiveBtn{
  color: #ffffff !important;
  background-color: #272b36 !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  border-radius: 12px !important;
}

.infoThreeD {
  width: 28px;
  margin-left: 8px;
  cursor: pointer;
}

.infoPopup {
  position: absolute;
  z-index: 99;
  background-color: #232732;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 6px #ccc;
  max-width: 400px;
  bottom: 40px;
  left: 150px;
}

.cancelBtn{
  color: #eb3f35 !important;
  background-color: #272b36 !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  border-radius: 12px !important;
}

@media only screen and (max-width: 767px) {
  .infoHeader{
    font-size: 18px;
    padding: 20px;
    font-weight: 500;
  }

  .infoContent{
    font-size: 14px;
  }

  .infoLabel{
    font-size: 12px;
  }

  .infoBody{
    padding: 0px 20px 18px;
  }

  .infoBodyColumn{
    flex-direction: column;
  }

  .infoBodyItem{
    width: 100%;
  }

  .infoBtnContainer{
    flex-direction: column;
  }

  .infoBtnContainer button:first-child{
    margin-bottom: 10px !important;
  }

  .infoBtnContainer button{
    margin: 0px !important;
    font-size: 14px !important;
  }
}

.licenseDetailContainer {
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  height: 40%;
  position: relative;
}
.orderSearchHolder {
    position: relative;
    display: inline;
}

.orderSearchIcon {
    position: absolute;
    top: 50%;
    right: 19px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.orderSearchHolder .orderSearch {
    width: 320px;
    background-color: #272b36;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    margin-right: 10px;
    height: 100%;
    font-size: 18px;
}

.orderSearchHolder input {
    background-color: #272b36;
    color: #ffffff;
    font-size: 18px;
}

.orderHistoryTable {
    /*min-height: 455px;*/

    /*
    display: flex;
    flex-direction: column;
    justify-content: center;
    */
}

.orderHistoryTable div,
.orderHistoryTable th {
    background-color: #232732 !important;
}

.orderItemIcon {
    cursor: pointer;
}

.orderItemIcon:hover {
    fill: #0D9FFF;
}

.tableProductIcon {
    width: 50px;
    background-color: #ffffff;
    border-radius: 10px;
}

.tableProductTitle {
    padding-left: 10px;
}

.orderStatisticDetails {
    /*padding: 25px;*/

    padding: 8px 25px 0px 25px;
}

.detailsBack {
    color: #0D9FFF;
    font-weight: bold;
    display: flex;
    cursor: pointer;
    padding-bottom: 8px;
}

.orderDetailsTop {
    display: flex;
    justify-content: space-between;
}

.orderDetailsImg img {
    width: 80px;
    background-color: #ffffff;
    border-radius: 10px;
}

.orderDetailsLeft,
.orderDetailsRight {
    width: 48%;
}

.orderDetailsLeft {
    display: flex;
}

.orderDetailsRight {
    padding: 0px;
}

.orderDetailsContent {
    padding-left: 10px;
}

.orderDetailCard {
    padding: 20px !important;
}

.orderHistoryTable .ant-pagination {
    padding-right: 10px !important;
}

.tableColumnProduct {
    display: flex;
    align-items: center;
}

.orderHistorySpinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.orderHistorySpinner .ant-spin-lg .ant-spin-dot {
    font-size: 80px !important;
}

.orderHistorySpinner .ant-spin-lg .ant-spin-dot i {
    width: 30px !important;
    height: 30px !important;
}

#userOrderTotal .ant-table-row {
    height: 72px;
}

#userOrderSingle .ant-table-row {
    height: 45px;
}

.ant-pagination-item {
    border-radius: 50% !important;
    text-align: center !important;
    font-family: 'Inter', sans-serif !important;
    border: none !important;
    margin-right: 3px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.ant-pagination-item a {
    color: #fff !important;
}

.ant-pagination-item-active {
    background-color: #0D9FFF !important;
    color: #fff !important;
}

.ant-pagination-item-active .ant-pagination-item-link {
    color: #fff !important;
}

.ant-pagination-next {
    margin-left: 5px !important;
}

.ant-pagination-item-link {
    border: none !important;
}

@media only screen and (max-width: 767px) {

    .contentName,
    .contentColor,
    .contentPrice,
    .selectedDateInnerLabel,
    .selectedDateInnerAmount {
        font-size: 12px;
    }

    .orderDetailsTop {
        flex-direction: column;
    }

    .orderDetailsLeft {
        margin-bottom: 20px;
    }

    .orderDetailsLeft,
    .orderDetailsRight {
        width: 100% !important;
    }
}

@media only screen and (min-width: 768px) {
    .orderHistoryContainer {
        /*height: calc(100% - 264px);*/
        height: 60%;
    }
}

.orderHistoryTable .ant-table-tbody>tr.ant-table-placeholder:hover>td div {
    background: #141414 !important;
}

.itemSpent {
    color: #FF9E2D;
}

/*added no hover*/
.ant-table-tbody>tr.ant-table-row:hover>td {
    background: none !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 11px 11px !important;
}
.dropdownContainer{
    position: relative;
}

.dropdwonLabel{
    padding: 15px;
    cursor: pointer;
    background-color: #272b36;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 220px;
}

.dropdownSelected{
    font-size: 16px;
    padding-left: 10px;
}

.arrowDownIcon {
    margin-left: 25px;
    position: absolute;
    right: 10px;
}

.arrowUpIcon {
    margin-left: 25px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    right: 10px;
}

.optionSelection{
    position: absolute;
    background-color: #272b36;
    border-radius: 10px;
    width: 100%;
    height: auto;
    z-index: 99;
    top: 70px;
}

.shadowBox{
    box-shadow: 1px 1px 6px #ccc;
}

.optionItems{
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 10px;
}

.calendarIcom{
    width: 25px;
}

@media only screen and (max-width: 767px) {
    .optionItems{
        font-size: 14px;
        padding: 6px 15px;
    }
}

@media only screen and (min-width: 768px) {
    .optionItems:hover {
        background-color: #1f2129;
        font-size: 20px;
    }
}
.mobileProductCardContainer{
    padding: 20px;
    background-color: #232732;
}

.mobileCardHeader{
    display: flex;
}

.mobileProductTitle{
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.mobileProductLabel{
    padding-left: 10px;
    font-size: 14px;
    color: #bdbac4;
    font-weight: 500;
}

.mobileProductInfo{
    display: flex;
    justify-content: space-between;
    padding: 2px 0px;
}

.mobileProductInfoLeft{
    color: #bdbac4;
    font-weight: 500;
}

.mobileProductInfoRight{
    font-weight: 500;
}

.mobileProductFooter{
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #0D9FFF;
    justify-content: end;
}

.detailBlueIcon{
    padding-left: 10px;
}

@media only screen and (max-width: 767px) {
    .mobileProductCardContainer{
        padding: 0px;
    }

    .mobileOrderDetailTable .ant-table-thead > tr > th,
    .mobileOrderDetailTable .ant-table-tbody > tr > td,
    .mobileOrderDetailTable .ant-table tfoot > tr > th,
    .mobileOrderDetailTable .ant-table tfoot > tr > td{
        padding: 16px 0px !important;
    }
}
.orderStatisticBody{
  padding: 0px 32px 32px;
  display: flex;
  justify-content: space-between;
  grid-column-gap: 2%;
}

.orderStatisticItem{
  width: 50%;
  display: flex;
  align-items: center;
}

.statisticItemLeft{
  width: 40%;
}

.statisticItemLeftTop{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.statisticItemMid{
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.statisticItemLabel{
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
}

.statisticItemIcom{
  width: 40px;
}

.statisticItemMidData{
  font-size: 44px;
  font-weight: bold;
}

.statisticItemMidUnit{
  font-size: 18px;
  font-weight: bold;
  color: #bdbac4;
  padding-left: 10px;
}

.statisticItemBottom{
  font-size: 18px;
  font-weight: 500;
}

.colorGreen {
  color: #1ec288;
}

.colorRed{
  color: #eb3f35;
}

.statisticChart{
  width: 60%;
  height: 100%;
}

.statisticChart > div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderStatisticTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}

.orderStatisticHeader{
  padding: 0 !important;
}

.orderStatisticSelection{
  display: flex;
}

@media only screen and (max-width: 767px) {
  .orderStatisticTop{
    align-items: start;
    padding: 20px;
    flex-direction: column;
  }

  .orderStatisticSelection{
    padding-top: 10px;
    flex-direction: column;
    width: 100%;
  }

  .orderSearchHolder .orderSearch{
    margin-right: 0px !important;
    width: 100% !important;
  }

  .dropdwonLabel{
    padding: 12px;
    width: 100%;
  }

  .dropdownSelected,
  .statisticItemMidUnit,
  .statisticItemLabel{
    font-size: 14px;
  }

  .orderStatisticBody{
    flex-direction: column;
    padding: 0px 24px 20px;
  }

  .orderStatisticItem{
    width: 100%;
  }

  .statisticItemMidData{
    font-size: 32px;
  }

  .desktopView{
    display: none;
  }

  .statisticItemLeft,
  .statisticChart{
    width: 50%;
  }

  .orderSearchHolder{
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .mobileView{
    display: none;
  }

  .orderStatisticContainer{
    /*height: 264px;*/
    height: 40%;
  }  

  .orderStatisticTop{
    height: 122px;
  }

  .orderStatisticBody{
    height: calc(100% - 122px);
  }
}
.calendarContainer>div:nth-child(2) {
  background-color: #232732;
  padding: 0px 20px;
}

.calendarContainer>div:nth-child(2)>div {
  background-color: #232732 !important;
}

.calendarContainer ant-select-selector {
  background-color: #232732;
}


.upcomingDropsContainer ant-select-selector {
  background-color: #232732;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  min-width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
  background-color: #0D9FFF !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
  border: 1px solid #0D9FFF !important;
}

.ant-picker-calendar-date-today,
.ant-picker-calendar-date-today.ant-picker-calendar-date-today {
  border: none !important;
}

.ant-picker-cell {
  min-width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
}

.ant-picker-cell-inner {
  min-width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
}

.ant-picker-calendar-date-today::before {
  content: '';
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 4px;
}

.listTable {
  list-style-type: none;
  position: absolute;
  
  right: 22%;
  top: 35%;
  
}

/* .ant-picker-cell-selected .listTable {
  right: 2%;
} */

.cellItem span span {
  background-color: #0D9FFF;
}

.ant-picker-cell-selected .cellItem span span {
  background-color: white !important;
}

.productItemTitle,
.productItemSku,
.productItemPrice {
  line-height: 15px;
}

.ant-picker-calendar-header div:nth-child(3) {
  display: none;
}

.selectedDate {
  padding: 0px 40px !important;
  margin-bottom: 20px;
}

.selectedDateInner {
  background-color: #272b36 !important;
  padding: 10px 20px !important;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.selectedDateInnerAmount {
  font-weight: 500;
}

@media only screen and (min-width: 768px) {
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 8px 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .selectedDate {
    padding: 0px !important;
  }
}

.calenderHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 23px;
}

.calenderIcon {
  padding: 5px;
  cursor: pointer;
  width: 40px;
}

.rotateCalenderIcon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  width: 40px;
}

.releaseCalenderContainer {
  height: 100%;
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  position: relative;
}

.dateText {
  font-size: 18px;
  color: #ffffff;
}

.productBody {
  padding-bottom: 3px;
  height: 330px;
  overflow-y: auto;
}
.productCardTop{
    padding: 0px 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.productCardImg{
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}

.productCardImg img{
    width: 70px;
}

.productCardContent{
    font-size: 14px;
    font-weight: bold;
}

.cardFixing {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.contentColor{
    color: #bdbac4;
}

.productCardBottom{
    padding: 0px 40px;
    margin-bottom: 20px;
}

.productCardBtn{
    padding: 0px 40px;
}

.productCardLabel{
    background-color: #FF9E2D;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 40px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.productCardBottom{
    display: flex;
    justify-content: space-between;
}

.productCardBottomLeft,
.productCardBottomRight{
    width: 48%;
    background-color: #272b36;
    padding: 10px;
    border-radius: 10px;
}

.productLabel{
    color: #bdbac4;
    font-size: 12px;
}

.productContent{
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .productCardTop,
    .productCardBtn{
        padding: 0px 20px;
    }

    .productCardBtn button{
        font-size: 14px !important;
    }
}
.adminStatHeader{
    padding: 20px;
    position: relative;
}

.adminStatHeaderLabel{
    text-align: left;
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
}

.adminStatHeaderRight{
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
}

.lastUpdateTime{
    color: #bdbac4;
    padding-right: 20px;
}

.adminStatBodyTop{
    padding: 20px;
}

.adminStatBodyTopInner{
    padding: 20px;
    background-color: #232732;
    display: flex;
    grid-column-gap: 1%;
    border-radius: 10px;
}

.adminItem{
    width: 33%;
}

.adminItemAmount{
    color:#ffffff;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 2px;
    padding-right: 10px;
}

.adminItemLabel{
    color: #bdbac4;
    font-weight: 500;
}

.adminItemTop{
    display: flex;
    align-items: baseline;
}

.successCheckout{
    color: #0D9FFF;
}

.failedCheckout{
    color: #FF9E2D;
}

.adminItemBottom{
    display: flex;
}

.adminItemBottomAmount{
    padding-right: 5px;
}

.adminItemBottomLabel{

}

.adminItemBottomLabel{
    color: #bdbac4;
}

.adminStatBodyBottom{
    padding: 20px;
}
.productCard {
    display: flex;
    justify-content: space-between;
    /*box-shadow: 1px 1px 6px #ccc;*/
    background-color: #232732;
    border-radius: 15px;
    padding: 16px;
    height: 260px;
    margin: 5px;
}

.pic {
    
    
    height: 170px;
    width: 170px;
}

.properties {
    
    margin: 2px;
}

.productStore {
    font-weight: bold;
    font-size: 15px;
}

.left, .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkouts {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.checkoutNumber{
    color:#ffffff;
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 2px;
    padding-right: 5px;
}

.allCheckouts {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
}

.checkoutLabel {
    color: #bdbac4;
    font-weight: 500;
}

.successCheckout{
    color: #0D9FFF;
}

.failedCheckout{
    color: #FF9E2D;
}
.productCards {
    display: grid;
    
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));

     
}

.pagi {
    display: flex;
    justify-content: end;
    margin-top: 1px;
}

.headerProducts {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.refreshIcon {
    display: flex;
    justify-content: center;
    color: #0D9FFF !important;
    background-color: #272b36 !important;
    height: 45px !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 10px 15px !important;
    border-radius: 12px !important;
    margin-right: 5px;
    cursor: pointer;
}
.adminReleasesBody {
    
}
.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.adminButton button {
    background-color: #0D9FFF;
    color: #ffffff;
    border-radius: 10px;
    padding: 4px 25px;
    height: 45px;
    border: none;
    font-size: 18px;
    margin-right: 5px;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.absoluteRight {
    position: absolute;
    right: 20px;
}

.adminLicenseBodyTop {
    padding: 20px;
}

.adminStatLicenseTopInner {
    padding: 20px;
    background-color: #232732;
    display: flex;
    grid-column-gap: 1%;
    border-radius: 10px;
}

.adminLicenseItem {
    width: 25%;
}

.adminLicenseItemTop {
    display: flex;
    align-items: baseline;
}

.adminLicenseItemAmount {
    color: #ffffff;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 2px;
    padding-right: 10px;
}

.adminLicenseItemLabel {
    color: #bdbac4;
    font-weight: 500;
}

.openClientsColor {
    color: #0D9FFF;
}

.lifetimeColor {
    color: #FF9E2D;
}

.renewalColor {
    color: #1ec288;
}

.adminLicenseBodyBottom {
    padding: 20px;
}

.cardBtnContainerNew {
    display: flex;
    justify-content: center;

}

.cardBtnNew {
    color: #0D9FFF !important;
    background-color: #272b36 !important;
    height: 50px !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 10px 30px !important;
    width: 100% !important;
    border-radius: 12px !important;

}

.popupsuccess {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:9999;
}
.popupval {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:9999;
}

.valQuestion {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 10px;
}

.confirmButton {
    display: flex;
    margin-top: 10px !important;
    width: 100%;
    font-size: 18px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    height: 45px !important;
    align-items: center !important;
}


.form {
    max-width: 800px;
    margin: 0 auto;
    /*font-family: sans-serif;*/
  }

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }

input[type="submit"],
.button {
    background: #ec5990;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 20px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
    display: block;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 10px;
    width: 100%;
    font-weight: lighter;
  }
.paymentRoot{
  max-width: 500;
  margin: 35vh auto;
}

.paymentContent{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.btnContainer{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
}

.paymentBtn{
  margin: '2em auto 1em';
}
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin: auto;
    background-color: #1f2129;
    color: black;
    padding: 24px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 16px;
    width: 500px;
  }

  .form {
    max-width: 800px;
    margin: 0 auto;
    /*font-family: sans-serif;*/
  }

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border: none !important;
    color: #ffffff !important;
    background-color: #272b36;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }

input[type="submit"],
.button {
    background-color: #0D9FFF;
    color: white;
    text-transform: initial;
    border: none;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    display: block;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 10px;
    width: 100%;
    font-weight: bold;
    letter-spacing: 0px;
  }

.updateAddressTitle{
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
}

.updateAddressLabel{
  color: #ffffff;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.updateAddressLabelStar{
  color: #0D9FFF;
  padding-left: 5px;
  font-weight: bold;
}

.updateAddressColumn{
  padding-bottom: 10px;
}

.closeIcon{
  position: absolute;
  right: 24px;
  cursor: pointer;
}

.halfContainer{
  display: flex;
  justify-content: space-between;
}

.updateAddressColumnHalf{
  width: 48%;
}

.submitButton{
  cursor: pointer;
}

.backToBtn{
  color: #ffffff !important;
  background-color: #0D9FFF !important;
  border: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  width: 100% !important;
  border-radius: 12px !important;
}

.resultIconContainer{
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.resultTitle{
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  font-size: 20px;
}

.resultDesc{
  color: #bdbac4;
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .popup_inner {
    width: 90%;
    height: auto;
  }

  .updateAddressTitle{
    font-size: 20px;
  }

  .updateAddressLabel{
    font-size: 14px;
    padding-bottom: 6px;
  }

  .updateAddressColumnHalf{
    width: 100%;
  }

  .halfContainer{
    flex-direction: column;
  }

  .updateAddressColumn{
    padding-bottom: 6px;
  }

  .backToBtn{
    font-size: 14px !important;
  }
}
.updateCardTitle{
  display: flex;
  align-items: baseline;
  font-size: 24px;
}

.updateCardSubTitle{
  padding-left: 5px;
  color: #bdbac4;
  font-size: 16px;
}

.updatePaymentRoot{
  /* max-width: 500px; */
  /* margin: 35vh auto; */
}

.updatePaymentContent{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.btnContainer{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
}

.paymentBtn{
  margin: '2em auto 1em';
}

.stripCardInputSection  > div:nth-child(2) {
  padding: 15px 10px;
  background-color: #272b36;
  border-radius: 10px;
}

.updateCardDesc{
  padding-top: 10px;
  color: #bdbac4;
  font-size: 14px;
}

.updateCardFooter{
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.headerContainer {
  background-color: #1f2129;
  padding: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
}

.headerIconDivider {
  margin: 0px 20px 0px 20px !important;
  height: 40px !important;
  border-left: 1px solid #303030 !important;
}

.header {
  font-size: 40px;
}

.pop {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.headerIcon {
  width: 30px;
  margin-right: 10px;
}

.logoIcon {
  cursor: pointer;
}

.logoIcon img {
  width: 40px;
}

.headerButtonItemSelected button {
  background-color: #0D9FFF;
  color: #ffffff;
  border-radius: 10px;
  padding: 4px 16px;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.headerButtonItemSelected button:hover {
  color: #ffffff;
  background-color: #0D9FFF;
}

.headerButtonItemUnselected button {
  background-color: #1f2129 !important;
  color: #ffffff;
  border-radius: 10px;
  padding: 4px 16px;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

/*
.headerButtonItemUnselected button:hover {
  color: #ffffff;
  background-color: #1f2129;
}
*/

.notificationIconHolder {
  position: relative;
  cursor: pointer;
}

.notificationIcon {
  margin-right: 25px;
  background-color: #232732;
  padding: 10px;
  border-radius: 10px;
}

.headerRight {
  display: flex;
  position: absolute;
  right: 20px;
  align-items: center;
}

.notificationIconHolder .ant-badge-dot {
  top: 13px;
  right: 40px;
  width: 10px;
  height: 10px;
}

.notificationIconHolder img {
  width: 44px;
}

.userAvatar {
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
}

.headerMobile {
  flex-direction: row;
}

.marginNone {
  margin: 0px;
}

.headerMobileMenu {
  position: absolute;
  top: 75px;
  z-index: 99;
  width: 100%;
  background-color: rgba(31, 33, 41, 0.9);
  padding: 10px;
}

.headerMobileInfo {
  padding: 10px 0px;
  display: flex;
}

.headerMobileAvatar {
  padding-right: 20px;
}

.headerMobileTag {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}

.headerMobileLogout {
  color: #FF3C61;
  font-weight: 500;
  position: relative;
}

.headerMobileLogout:after {
  content: ">";
  position: absolute;
  left: 60px;
  font-size: 12px;
  top: -1px;
  -webkit-transform: scaleY(2);
          transform: scaleY(2);
}

.logoutDropdown {
  position: absolute;
  background-color: #272b36;
  border-radius: 10px;
  width: auto;
  height: auto;
  z-index: 99;
  top: 60px;
  padding: 10px;
  right: 0px;
}

.desktopDiscordTag {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 3px;
}

.desktopLogout {
  color: #FF3C61;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.desktopLogout:after {
  content: ">";
  position: absolute;
  left: 60px;
  font-size: 14px;
  -webkit-transform: scaleY(2);
          transform: scaleY(2);
  margin-left: 3px;
}


@media only screen and (max-width: 767px) {
  .headerDesktop {
    display: none;
  }

  .userAvatar {
    margin-left: 20px !important;
  }
}

@media only screen and (min-width: 768px) {
  .headerMobile {
    display: none;
  }

  .headerContainer {
    height: 108px;
  }
}
.paymentHeader{
  border-bottom: 1px solid #303030;
}

.paymentBody{
  padding-bottom: 32px;
  /*height: 300px;*/
  height: 400px;
  overflow-y: auto;
}

.paymentInfoContainer {
  height: 60%;
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  position: relative;
}

.paymentCard{
    padding: 20px 32px 20px;
    display: flex;
    flex-direction: column;
}

.paymentCardItem{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.paymentCardInner{
    padding-bottom: 20px;
    border-bottom: 1px solid #303030;
}

.viewInvoiceCta{
    font-size: 16px;
    color: #0D9FFF;
    cursor: pointer;
    font-weight: bold;
}

.viewInvoiceCtaGroup{
    display: flex;
    grid-column-gap: 10px;
}


@media only screen and (max-width: 767px) {
    .paymentCard{
        padding: 16px 20px;
    }

    .viewInvoiceCta{
        font-size: 12px;
    }

    .paymentBody{
        padding-bottom: 24px;
    }
}
.pop {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.cardPlaceholder {
  width: 100%;
}

.cardBox {
  position: relative;
}

.cardLast4 {
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
}

.cardType {
  position: absolute;
  bottom: 50px;
  right: 50px;
  bottom: 30px;
  right: 30px;
  width: 80px;
}

.cardLabel {
  padding: 0px 32px;
  font-size: 18px;
  color: #ffffff;
  position: relative;
}

.cardContent {
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;
}

.cardAddress {
  color: #bdbac4;
  padding: 0px 32px;
  font-size: 16px;
}

.cardContentHolder {
  padding-bottom: 10px;
}

.cardContentLeft {
  font-size: 16px;
  color: #bdbac4;
}

.cardContentRight {
  font-size: 16px;
  color: #ffffff;
}

.cardBtnContainer {
  padding: 0px 32px;
  margin-top: 90px;
}

.cardBtn {
  color: #0D9FFF !important;
  background-color: #272b36 !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  width: 100% !important;
  border-radius: 12px !important;
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 767px) {
  .cardLast4 {
    font-size: 14px;
  }

  .cardType {
    width: 60px;
  }

  .cardLabel {
    font-size: 14px;
    padding: 0px 20px;
  }

  .cardContent,
  .cardAddress {
    padding: 0px 20px;
  }

  .cardContentLeft,
  .cardContentRight,
  .cardAddress {
    font-size: 12px;
  }

  .cardBtnContainer {
    padding: 0px 20px;
    margin-top: 32px;
  }

  .cardBtnContainer button {
    font-size: 14px !important;
  }
}

.cardInfoContainer {
  height: 100%;
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  position: relative;
}
.planCard{
    width: 25%;
    background-color: #232732;
    border-radius: 10px;
    padding: 24px;
    position: relative;
    height: 470px;
}

.planCard:hover .planCardBtn{
    color: #ffffff !important;
    background-color: #0D9FFF !important;
}

.mostPopular{
    position: absolute;
    top: -35px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    color: #FF9E2D;
    background-color: rgb(255,158,45, 0.1);
    padding: 2px 15px;
    border-radius: 10px;
}

.planIconContainer{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.planIcon{
    width: 80px;
}

.planTitle{
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    font-weight: bold;
}

.planDesc{
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #bdbac4;
    color: #bdbac4;
    font-size: 14px;
}

.planPrice{
    text-align: center;
    font-size: 36px;
    padding: 20px 0px;
    color: #ffffff;
    font-weight: bold;
}

.planPrevPrice{
    text-align: center;
    color: #bdbac4;
    font-size: 14px;
    text-decoration: line-through;
}

.planDiscountRate{
    text-align: center;
    font-size: 14px;
    color: #1ec288;
    font-weight: bold;
}

.planBtn{
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
}

.planCardBtn{
    color: #0D9FFF !important;
    background-color: #272b36 !important;
    border: none !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 0px !important;
    height: 50px !important;
    width: 100% !important;
    border-radius: 12px !important;
}

@media only screen and (max-width: 767px) {
    .marketPlaceHeader{
        font-size: 24px;
    }

    .planCardSpace{
        margin-top: 30px;
    }

    .planTitle{
        font-size: 18px;
    }

    .planDesc{
        font-size: 12px;
    }

    .planCardBtn{
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 768px) {
    .planCard:hover{
        transition: all 0.2s ease-in-out;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        box-shadow: 1px 1px 6px #ccc;
    }
}

.orderSearchHolder {
    position: relative;
    display: inline;
}

.orderSearchIcon {
    position: absolute;
    top: 50%;
    right: 19px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.orderSearchHolder .orderSearch {
    width: 320px;
    background-color: #272b36;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    margin-right: 10px;
    height: 100%;
    font-size: 18px;
}

.orderSearchHolder input{
    background-color: #272b36;
    color: #ffffff;
    font-size: 18px;
}

.orderHistoryTable div,
.orderHistoryTable th {
    background-color: #232732 !important;
}

.orderItemIcon{
    cursor: pointer;
}

.tableProductIcon{
    width: 50px;
    background-color: #ffffff;
    border-radius: 10px;
}

.tableProductTitle{
    padding-left: 10px;
}

.orderStatisticDetails{
    /*padding: 25px;*/

    padding: 8px 25px 0px 25px;
}

.detailsBack{
    color: #0D9FFF;
    font-weight: bold;
    display: flex;
    cursor: pointer;
    padding-bottom: 8px;
}

.orderDetailsTop{
    display: flex;
    justify-content: space-between;

    height: 110px;
}

.orderDetailsImg img{
    width: 80px;
    background-color: #ffffff;
    border-radius: 10px;
}

.orderDetailsLeft,
.orderDetailsRight{
    width: 48%;
}

.orderDetailsLeft{
    display: flex;
}

.orderDetailsRight{
    padding: 0px;
}

.orderDetailsContent{
    padding-left: 10px;
}

.orderDetailCard{
    padding: 20px !important;
}

.orderHistoryTable .ant-pagination{
    padding-right: 10px !important;
}

.tableColumnProduct{
    display: flex;
    align-items: center;
}

.orderHistorySpinner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
}

.orderHistorySpinner .ant-spin-lg .ant-spin-dot{
    font-size: 80px !important;
}
  
.orderHistorySpinner .ant-spin-lg .ant-spin-dot i{
    width: 30px !important;
    height: 30px !important;
}



.globalHeadersContainer{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    max-width: 1240px;
    width: 84%;
    height: auto;
    background: rgba(43, 47, 58, 0.4);
    -webkit-backdrop-filter: blur(32px);
            backdrop-filter: blur(32px);
    border-radius: 16px;
    padding: 0px !important;
    margin: auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.globalHeaderLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-left: 16px;
}

.globalHeaderBtnGroup{
    display: flex;
}

.globalHeaderLabel{
    color: #ffffff;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
}

.globalHeaderLogo img{
    width: 30px;
    height: 30px;
}

.marketplaceBtn {
    background-color: #232730 !important;
    color: #0d9fff !important;
}
.globalFootersContainer{
    background-color: #272B36;
}

.footerInnerContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.globalFootersContainerTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #969cb057;
    padding: 32px 0 !important;
    grid-gap: 24px;
    gap: 24px;
}

.globalFooterLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.globalFooterLogo img{
    height: 44px;
    width: 44px;
}

.globalFooterLabel{
    color: #969CB0;
    padding-left: 20px;
    font-size: 24px;
    font-weight: 500;
}

.globalFooterLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
}

.globalFooterLinks a{
    color: #969CB0;
    font-size: 18px;
}

.globalFootersContainerBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px !important;
}

.copyright{
    font-size: 18px;
    color: #969CB0;
}

.footerBottomLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
}

.footerBottomLinks a{
    color: #969CB0;
    font-size: 18px;
}
.colorTrue{
    color: #1ec288;
}

.colorFalse{
    color: #eb3f35;
}

.avatarDetailsImg img{
    width: 80px;
    background-color: #ffffff;
    border-radius: 10px;
}
.popupnew {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:9999;
}



.customSelect {
    
    display: block !important;
    box-sizing: border-box !important;
    width: 100%;
    border-radius: 10px;
    border: none !important;
    color: #ffffff !important;
    
    background-color: #272b36;
    padding: 5px 10px !important;
    margin-bottom: 15px !important;
    font-size: 14px !important;
    
}



/* Preload aniamtion */
.preloader {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /*background: #fff;*/
    text-align: center;
  }
  
  .loading {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    height: 80px;
    margin: auto;
  }
  
  .animationLogo {
    overflow: hidden;
    vertical-align: middle;
  }
  
  .progress-wrapper {
    width: 90%;
    height: 2px;
    border-radius: 2px;
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    display: block;
    margin: auto;
    overflow: hidden;
    margin-top: 14px;
  }
  
  .progress {
    display: block;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 200px;
    height: 2px;
    left: -102px;
    background: radial-gradient(46.66% 46.66% at 49.32% 43.12%, #00C0F3 0%, #02BBEF 15%, #07ABE4 33%, #0F92D1 53%, #1A6FB7 74%, #284296 96%, #2B3990 100%);
    -webkit-animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  @-webkit-keyframes loader-anim {
    0% {
        left: -102px;
    }
  
    100% {
        left: 102px;
    }
  }
  
  @keyframes loader-anim {
    0% {
        left: -102px;
    }
  
    100% {
        left: 102px;
    }
  }
.container{
  margin: 0px auto;
  width: 100%;
  /*height: calc(100% - 108px);*/
  height: 900px;
  background-color: #1f2129;
  display: flex;
  justify-content: space-between;
  /* grid-column-gap: 1%; */
  color: #ffffff;
}

.containerLeft{
  width: 66%;
  grid-row-gap: 20px;
  display: flex;
  flex-direction: column;
}

.containerRight{
  /* width: 427px; */
  width: 33%;
}

.dashboardSpinner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardSpinner .ant-spin-lg .ant-spin-dot{
  font-size: 80px !important;
}

.dashboardSpinner .ant-spin-lg .ant-spin-dot i{
  width: 30px !important;
  height: 30px !important;
}

.dashboardContainer{
  /*max-width: 1200px;*/
  max-width: 1400px;
  margin: auto;
  /*padding-bottom: 32px;*/
}

.backgroundBlur{
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

@media only screen and (max-width: 767px) {
  .container{
    flex-direction: column;
  }

  .containerLeft,
  .containerRight{
    width: 100%;
  }

  .width100 button{
    width: calc(100% - 40px) !important;
    margin: 20px;
    font-size: 14px !important;
    font-weight: 500;
  }

  .calendarMobileMenu{
    position: absolute;
    top: 160px;
    z-index: 99;
    width: 100%;
    background-color: rgba(31,33,41, 0.9);
    padding: 10px;
  }
}

.landingContainer{
    height: 900px;
    position: relative;
    background-color: #16171D;
    padding-top: 120px;
    overflow: hidden;
}

.landingContainer::after {
    width: 100%;
    height: 200px;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
    180deg, rgba(22, 23, 29, 0) 0%, rgba(22, 23, 29, 0.9) 100%);
}

.landingInnerContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.heroHeader{
    padding-top: 60px;
}

.heroText{
    width: 40%;
    height: auto;
    text-align: center;
    margin: auto;
}

.heroTitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 16px;
    color: #ffffff;
}

.heroDesc{
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
}

.hideHero{
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.fadeInUp{
    visibility: visible !important;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
    -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
}

.textBlue{
    color: #0D9FFF;
}

.dashboardPurchaseBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0 48px 0 !important;
}

.dashboardPurchaseBtn button {
    padding: 10px 25px;
    height: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.purchaseIcon{
    width: 36px;
    margin-right: 10px;
}

.heroImage{
    width: 80%;
    height: 491px;
    margin: auto;
    text-align: center;
    position: relative;
}

.heroImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: middle;
    border-style: none;
    text-align: center;
}

.statusAtc,
.statusIdle{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.producPreviewContainer{
    padding: 120px 0px;
}

.producPreviewInnerContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;

}

.titleWrapper{
    margin-bottom: 48px !important;
}

.productLabel{
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #0D9FFF;
    margin-bottom: 4px !important;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    margin-top: 0;
}

.productTitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 40px !important;
    line-height: 50px !important;
    color: #fff;
}

.productDesc{
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
    margin-top: 0;
    margin-bottom: 0 !important;
}

.videoReview{
    width: 100%;
    height: 650px;
    overflow: hidden;
    background-color: #2B2F3A;
    border-radius: 16px;
    position: relative;
    margin-bottom: 100px;
}

.textCenter{
    text-align: center;
}

.productAnalytics{
    height: 400px;
    background: #232732;
    border-radius: 16px;
    padding: 48px 48px 0px 48px;
    overflow: hidden;
    margin-bottom: 48px;
    display: flex;
    grid-gap: 64px;
    gap: 64px;
}

.productTask{
    height: 400px;
    background: #232732;
    border-radius: 16px;
    padding: 48px 48px 0px 48px;
    overflow: hidden;
    margin-bottom: 48px;
}

.productContentLeft{
    width: 50%;
}

.productImg{
    width: 50%;
    height: 120%;
}

.productImg img{
    width: auto;
    height: 100%;
    vertical-align: middle;
    border-style: none;
}

.productInnerTitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 32px !important;
    line-height: 42px !important;
    color: #FFFFFF;
}

.displayFlex{
    width: 100%;
    display: flex;
}

.productLabelLeft,
.productLabelRight{
    width: 50%;
}

.productTaskImg{
    width: 80%;
    height: 100%;
    margin: auto;
    text-align: center;
    margin-top: 32px;
}

.productTaskImg img{
    width: 100%;
    height: auto;
}

.faqContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.faqInnerContainer{
    background: #16171D;
    border-radius: 16px;
    padding: 72px 80px;
}

.faqTitleContainer{
    margin-bottom: 48px !important;
    text-align: center !important;
}

.fatTitle{
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #0D9FFF;
    margin-bottom: 4px !important;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
}

.faqDesc{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 40px !important;
    line-height: 50px !important;
    color: #FFFFFF;
}

.faqBottomDesc{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0 !important;
    margin-top: 40px !important;
    text-align: center !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
}

.faqAccordion .ant-collapse{
    border: none !important;
    background-color: transparent !important;
}

.faqAccordion .ant-collapse-header{
    background-color: transparent !important;
    border: none !important;
    font-weight: 700 !important;
    color: #fff !important;
    padding: 32px !important;
    width: 100% !important;
    text-align: left !important;
    position: relative !important;
    cursor: pointer !important;
    -webkit-appearance: button !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 20px !important;
    font-family: 'Inter', sans-serif !important;
    line-height: 30px !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0 !important;
}

.faqAccordion .ant-collapse-arrow{
    font-size: 18px !important;
}

.faqAccordion .ant-collapse-content{
    background-color: transparent !important;
    border: none !important;
}

.faqAccordion .ant-collapse-content-box{
    padding: 0px 32px 32px 32px !important;
}

.faqAccordion .ant-collapse-content-box p{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
}

.faqAccordion .ant-collapse > .ant-collapse-item{
    background-color: #232732 !important;
    border-radius: 8px !important;
    margin-bottom: 24px !important;
    border: none !important;
}

.purchaseContainer{
    padding: 120px 0px;
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.purchaseInnerContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.purchaseTitleContainer{
    margin-bottom: 0 !important;
    text-align: center;
}

.purchaseTitle{
    margin-bottom: 16px !important;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 40px !important;
    line-height: 50px !important;
    color: #fff;
}

.purchaseDesc{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
    margin-top: 0;
}

.circle {
    margin: 25px 10px;
    width: 200px;
    color: #fff;
    font-size: 32px;
    line-height: 200px;
    text-align: center;
    height: 200px;
    border-radius: 100px;
  }
  .bg-yellow {
    background: #ffcc35;
  }
  .bg-green {
    background: #5bd5a0;
  }
  .bg-red {
    background: #eb3980;
  }
  .bg-sky{
    background-color: skyblue!important;
  }
.headerOutContainer{
    max-width: 1200px;
    margin: auto;
}

.headerContainer{
    position: relative;
}

.headerRightSection{
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
}

.headerName{
    font-weight: 500;
}

.adminArrowUpIcon{
    margin-left: 10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.adminArrowDownIcon{
    margin-left: 10px;
}
.marketPlaceContainer{
    background-color: #1f2129;
    padding-top: 120px;
}

.marketPlaceHeader{
    padding: 40px 0px 10px;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
}

.marketPlanText{
    color: #0D9FFF;
}

.marketPlaceDesc{
    color: #bdbac4;
    text-align: center;
}

.planContainer{
    display: flex;
    grid-column-gap: 2%;
    max-width: 1024px;
    margin: auto;
    padding: 80px 0px 40px;
}

@media only screen and (max-width: 767px) {
    .marketPlaceHeader{
        font-size: 24px;
    }

    .marketPlaceDesc{
        font-size: 12px;
    }

    .planContainer{
        flex-direction: column;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
        padding: 40px 0px 20px;
    }

    .planCard{
        width: 80%;
    }
}
.subscribeBtn {
    background-color: #0D9FFF;
    color: #ffffff;
    border-radius: 10px;
    padding: 4px 16px;
    height: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.subscriptionPageContainer {
    background-color: #1f2129;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

}

.downloadIcon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 36px;
    margin-right: 10px;
}

.checkoutUI {
    width: 1000px;
}

.subPadding {
    padding-top: 250px
}

/*
.preloader {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #fff;
    text-align: center;
}

.loading {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    height: 80px;
    margin: auto;
}

.animationLogo {
    overflow: hidden;
    vertical-align: middle;
}

.progress-wrapper {
    width: 90%;
    height: 2px;
    border-radius: 2px;
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    display: block;
    margin: auto;
    overflow: hidden;
    margin-top: 14px;
}

.progress {
    display: block;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 200px;
    height: 2px;
    left: -102px;
    background: radial-gradient(46.66% 46.66% at 49.32% 43.12%, #00C0F3 0%, #02BBEF 15%, #07ABE4 33%, #0F92D1 53%, #1A6FB7 74%, #284296 96%, #2B3990 100%);
    -webkit-animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@-webkit-keyframes loader-anim {
    0% {
        left: -102px;
    }

    100% {
        left: 102px;
    }
}

@-moz-keyframes loader-anim {
    0% {
        left: -102px;
    }

    100% {
        left: 102px;
    }
}

@keyframes loader-anim {
    0% {
        left: -102px;
    }

    100% {
        left: 102px;
    }
}
*/
.impressumContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1f2129;
    padding-top: 120px;
    overflow: hidden;
}

.textContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}
.notFoundContainer {
    background-color: #1f2129;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.notFoundText {
    color: #0D9FFF;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 20px;
}

.privacyPolicyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1f2129;
    padding-top: 120px;
    overflow: hidden;
  }
  
  .textPrivacyContainer {
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }



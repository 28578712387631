.headerContainer {
  background-color: #1f2129;
  padding: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
}

.headerIconDivider {
  margin: 0px 20px 0px 20px !important;
  height: 40px !important;
  border-left: 1px solid #303030 !important;
}

.header {
  font-size: 40px;
}

.pop {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.headerIcon {
  width: 30px;
  margin-right: 10px;
}

.logoIcon {
  cursor: pointer;
}

.logoIcon img {
  width: 40px;
}

.headerButtonItemSelected button {
  background-color: #0D9FFF;
  color: #ffffff;
  border-radius: 10px;
  padding: 4px 16px;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.headerButtonItemSelected button:hover {
  color: #ffffff;
  background-color: #0D9FFF;
}

.headerButtonItemUnselected button {
  background-color: #1f2129 !important;
  color: #ffffff;
  border-radius: 10px;
  padding: 4px 16px;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

/*
.headerButtonItemUnselected button:hover {
  color: #ffffff;
  background-color: #1f2129;
}
*/

.notificationIconHolder {
  position: relative;
  cursor: pointer;
}

.notificationIcon {
  margin-right: 25px;
  background-color: #232732;
  padding: 10px;
  border-radius: 10px;
}

.headerRight {
  display: flex;
  position: absolute;
  right: 20px;
  align-items: center;
}

.notificationIconHolder .ant-badge-dot {
  top: 13px;
  right: 40px;
  width: 10px;
  height: 10px;
}

.notificationIconHolder img {
  width: 44px;
}

.userAvatar {
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
}

.headerMobile {
  flex-direction: row;
}

.marginNone {
  margin: 0px;
}

.headerMobileMenu {
  position: absolute;
  top: 75px;
  z-index: 99;
  width: 100%;
  background-color: rgba(31, 33, 41, 0.9);
  padding: 10px;
}

.headerMobileInfo {
  padding: 10px 0px;
  display: flex;
}

.headerMobileAvatar {
  padding-right: 20px;
}

.headerMobileTag {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}

.headerMobileLogout {
  color: #FF3C61;
  font-weight: 500;
  position: relative;
}

.headerMobileLogout:after {
  content: ">";
  position: absolute;
  left: 60px;
  font-size: 12px;
  top: -1px;
  transform: scaleY(2);
}

.logoutDropdown {
  position: absolute;
  background-color: #272b36;
  border-radius: 10px;
  width: auto;
  height: auto;
  z-index: 99;
  top: 60px;
  padding: 10px;
  right: 0px;
}

.desktopDiscordTag {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 3px;
}

.desktopLogout {
  color: #FF3C61;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.desktopLogout:after {
  content: ">";
  position: absolute;
  left: 60px;
  font-size: 14px;
  transform: scaleY(2);
  margin-left: 3px;
}


@media only screen and (max-width: 767px) {
  .headerDesktop {
    display: none;
  }

  .userAvatar {
    margin-left: 20px !important;
  }
}

@media only screen and (min-width: 768px) {
  .headerMobile {
    display: none;
  }

  .headerContainer {
    height: 108px;
  }
}
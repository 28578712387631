.landingContainer{
    height: 900px;
    position: relative;
    background-color: #16171D;
    padding-top: 120px;
    overflow: hidden;
}

.landingContainer::after {
    width: 100%;
    height: 200px;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
    180deg, rgba(22, 23, 29, 0) 0%, rgba(22, 23, 29, 0.9) 100%);
}

.landingInnerContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.heroHeader{
    padding-top: 60px;
}

.heroText{
    width: 40%;
    height: auto;
    text-align: center;
    margin: auto;
}

.heroTitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 16px;
    color: #ffffff;
}

.heroDesc{
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
}

.hideHero{
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.fadeInUp{
    visibility: visible !important;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
}

.textBlue{
    color: #0D9FFF;
}

.dashboardPurchaseBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0 48px 0 !important;
}

.dashboardPurchaseBtn button {
    padding: 10px 25px;
    height: 50px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.purchaseIcon{
    width: 36px;
    margin-right: 10px;
}

.heroImage{
    width: 80%;
    height: 491px;
    margin: auto;
    text-align: center;
    position: relative;
}

.heroImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: middle;
    border-style: none;
    text-align: center;
}

.statusAtc,
.statusIdle{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.producPreviewContainer{
    padding: 120px 0px;
}

.producPreviewInnerContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;

}

.titleWrapper{
    margin-bottom: 48px !important;
}

.productLabel{
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #0D9FFF;
    margin-bottom: 4px !important;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    margin-top: 0;
}

.productTitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 40px !important;
    line-height: 50px !important;
    color: #fff;
}

.productDesc{
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
    margin-top: 0;
    margin-bottom: 0 !important;
}

.videoReview{
    width: 100%;
    height: 650px;
    overflow: hidden;
    background-color: #2B2F3A;
    border-radius: 16px;
    position: relative;
    margin-bottom: 100px;
}

.textCenter{
    text-align: center;
}

.productAnalytics{
    height: 400px;
    background: #232732;
    border-radius: 16px;
    padding: 48px 48px 0px 48px;
    overflow: hidden;
    margin-bottom: 48px;
    display: flex;
    gap: 64px;
}

.productTask{
    height: 400px;
    background: #232732;
    border-radius: 16px;
    padding: 48px 48px 0px 48px;
    overflow: hidden;
    margin-bottom: 48px;
}

.productContentLeft{
    width: 50%;
}

.productImg{
    width: 50%;
    height: 120%;
}

.productImg img{
    width: auto;
    height: 100%;
    vertical-align: middle;
    border-style: none;
}

.productInnerTitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 32px !important;
    line-height: 42px !important;
    color: #FFFFFF;
}

.displayFlex{
    width: 100%;
    display: flex;
}

.productLabelLeft,
.productLabelRight{
    width: 50%;
}

.productTaskImg{
    width: 80%;
    height: 100%;
    margin: auto;
    text-align: center;
    margin-top: 32px;
}

.productTaskImg img{
    width: 100%;
    height: auto;
}

.faqContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.faqInnerContainer{
    background: #16171D;
    border-radius: 16px;
    padding: 72px 80px;
}

.faqTitleContainer{
    margin-bottom: 48px !important;
    text-align: center !important;
}

.fatTitle{
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #0D9FFF;
    margin-bottom: 4px !important;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
}

.faqDesc{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 40px !important;
    line-height: 50px !important;
    color: #FFFFFF;
}

.faqBottomDesc{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0 !important;
    margin-top: 40px !important;
    text-align: center !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
}

.faqAccordion .ant-collapse{
    border: none !important;
    background-color: transparent !important;
}

.faqAccordion .ant-collapse-header{
    background-color: transparent !important;
    border: none !important;
    font-weight: 700 !important;
    color: #fff !important;
    padding: 32px !important;
    width: 100% !important;
    text-align: left !important;
    position: relative !important;
    cursor: pointer !important;
    -webkit-appearance: button !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 20px !important;
    font-family: 'Inter', sans-serif !important;
    line-height: 30px !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0 !important;
}

.faqAccordion .ant-collapse-arrow{
    font-size: 18px !important;
}

.faqAccordion .ant-collapse-content{
    background-color: transparent !important;
    border: none !important;
}

.faqAccordion .ant-collapse-content-box{
    padding: 0px 32px 32px 32px !important;
}

.faqAccordion .ant-collapse-content-box p{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
}

.faqAccordion .ant-collapse > .ant-collapse-item{
    background-color: #232732 !important;
    border-radius: 8px !important;
    margin-bottom: 24px !important;
    border: none !important;
}

.purchaseContainer{
    padding: 120px 0px;
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.purchaseInnerContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.purchaseTitleContainer{
    margin-bottom: 0 !important;
    text-align: center;
}

.purchaseTitle{
    margin-bottom: 16px !important;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 40px !important;
    line-height: 50px !important;
    color: #fff;
}

.purchaseDesc{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #969CB0;
    margin-top: 0;
}

.circle {
    margin: 25px 10px;
    width: 200px;
    color: #fff;
    font-size: 32px;
    line-height: 200px;
    text-align: center;
    height: 200px;
    border-radius: 100px;
  }
  .bg-yellow {
    background: #ffcc35;
  }
  .bg-green {
    background: #5bd5a0;
  }
  .bg-red {
    background: #eb3980;
  }
  .bg-sky{
    background-color: skyblue!important;
  }
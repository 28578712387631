.productCardTop{
    padding: 0px 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.productCardImg{
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}

.productCardImg img{
    width: 70px;
}

.productCardContent{
    font-size: 14px;
    font-weight: bold;
}

.cardFixing {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.contentColor{
    color: #bdbac4;
}

.productCardBottom{
    padding: 0px 40px;
    margin-bottom: 20px;
}

.productCardBtn{
    padding: 0px 40px;
}

.productCardLabel{
    background-color: #FF9E2D;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 40px;
    height: fit-content;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.productCardBottom{
    display: flex;
    justify-content: space-between;
}

.productCardBottomLeft,
.productCardBottomRight{
    width: 48%;
    background-color: #272b36;
    padding: 10px;
    border-radius: 10px;
}

.productLabel{
    color: #bdbac4;
    font-size: 12px;
}

.productContent{
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .productCardTop,
    .productCardBtn{
        padding: 0px 20px;
    }

    .productCardBtn button{
        font-size: 14px !important;
    }
}
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background-color: #1f2129;
    color: black;
    padding: 24px;
    height: fit-content;
    border-radius: 16px;
    width: 500px;
  }

  .form {
    max-width: 800px;
    margin: 0 auto;
    /*font-family: sans-serif;*/
  }

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border: none !important;
    color: #ffffff !important;
    background-color: #272b36;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }

input[type="submit"],
.button {
    background-color: #0D9FFF;
    color: white;
    text-transform: initial;
    border: none;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    display: block;
    appearance: none;
    border-radius: 10px;
    width: 100%;
    font-weight: bold;
    letter-spacing: 0px;
  }

.updateAddressTitle{
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
}

.updateAddressLabel{
  color: #ffffff;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.updateAddressLabelStar{
  color: #0D9FFF;
  padding-left: 5px;
  font-weight: bold;
}

.updateAddressColumn{
  padding-bottom: 10px;
}

.closeIcon{
  position: absolute;
  right: 24px;
  cursor: pointer;
}

.halfContainer{
  display: flex;
  justify-content: space-between;
}

.updateAddressColumnHalf{
  width: 48%;
}

.submitButton{
  cursor: pointer;
}

.backToBtn{
  color: #ffffff !important;
  background-color: #0D9FFF !important;
  border: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  width: 100% !important;
  border-radius: 12px !important;
}

.resultIconContainer{
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.resultTitle{
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  font-size: 20px;
}

.resultDesc{
  color: #bdbac4;
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .popup_inner {
    width: 90%;
    height: auto;
  }

  .updateAddressTitle{
    font-size: 20px;
  }

  .updateAddressLabel{
    font-size: 14px;
    padding-bottom: 6px;
  }

  .updateAddressColumnHalf{
    width: 100%;
  }

  .halfContainer{
    flex-direction: column;
  }

  .updateAddressColumn{
    padding-bottom: 6px;
  }

  .backToBtn{
    font-size: 14px !important;
  }
}
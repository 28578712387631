@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
/*
body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

body {
  font-family: 'Inter', sans-serif !important;
}

#root {
  height: 100%;
  background-color: #1f2129;
  overflow-y: auto;
}

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

.ant-table-wrapper {
  height: 100%;
  overflow: hidden;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ant-table {
  max-height: 90%;
  overflow: auto;
}

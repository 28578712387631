.popupval {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:9999;
}

.valQuestion {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 10px;
}

.confirmButton {
    display: flex;
    margin-top: 10px !important;
    width: 100%;
    font-size: 18px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    height: 45px !important;
    align-items: center !important;
}

.globalFootersContainer{
    background-color: #272B36;
}

.footerInnerContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.globalFootersContainerTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #969cb057;
    padding: 32px 0 !important;
    gap: 24px;
}

.globalFooterLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.globalFooterLogo img{
    height: 44px;
    width: 44px;
}

.globalFooterLabel{
    color: #969CB0;
    padding-left: 20px;
    font-size: 24px;
    font-weight: 500;
}

.globalFooterLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.globalFooterLinks a{
    color: #969CB0;
    font-size: 18px;
}

.globalFootersContainerBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px !important;
}

.copyright{
    font-size: 18px;
    color: #969CB0;
}

.footerBottomLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.footerBottomLinks a{
    color: #969CB0;
    font-size: 18px;
}
.adminStatHeader{
    padding: 20px;
    position: relative;
}

.adminStatHeaderLabel{
    text-align: left;
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
}

.adminStatHeaderRight{
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
}

.lastUpdateTime{
    color: #bdbac4;
    padding-right: 20px;
}

.adminStatBodyTop{
    padding: 20px;
}

.adminStatBodyTopInner{
    padding: 20px;
    background-color: #232732;
    display: flex;
    grid-column-gap: 1%;
    border-radius: 10px;
}

.adminItem{
    width: 33%;
}

.adminItemAmount{
    color:#ffffff;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 2px;
    padding-right: 10px;
}

.adminItemLabel{
    color: #bdbac4;
    font-weight: 500;
}

.adminItemTop{
    display: flex;
    align-items: baseline;
}

.successCheckout{
    color: #0D9FFF;
}

.failedCheckout{
    color: #FF9E2D;
}

.adminItemBottom{
    display: flex;
}

.adminItemBottomAmount{
    padding-right: 5px;
}

.adminItemBottomLabel{

}

.adminItemBottomLabel{
    color: #bdbac4;
}

.adminStatBodyBottom{
    padding: 20px;
}
.adminButton button {
    background-color: #0D9FFF;
    color: #ffffff;
    border-radius: 10px;
    padding: 4px 25px;
    height: 45px;
    border: none;
    font-size: 18px;
    margin-right: 5px;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.absoluteRight {
    position: absolute;
    right: 20px;
}

.adminLicenseBodyTop {
    padding: 20px;
}

.adminStatLicenseTopInner {
    padding: 20px;
    background-color: #232732;
    display: flex;
    grid-column-gap: 1%;
    border-radius: 10px;
}

.adminLicenseItem {
    width: 25%;
}

.adminLicenseItemTop {
    display: flex;
    align-items: baseline;
}

.adminLicenseItemAmount {
    color: #ffffff;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 2px;
    padding-right: 10px;
}

.adminLicenseItemLabel {
    color: #bdbac4;
    font-weight: 500;
}

.openClientsColor {
    color: #0D9FFF;
}

.lifetimeColor {
    color: #FF9E2D;
}

.renewalColor {
    color: #1ec288;
}

.adminLicenseBodyBottom {
    padding: 20px;
}

.cardBtnContainerNew {
    display: flex;
    justify-content: center;

}

.cardBtnNew {
    color: #0D9FFF !important;
    background-color: #272b36 !important;
    height: 50px !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 10px 30px !important;
    width: 100% !important;
    border-radius: 12px !important;

}

.popupsuccess {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:9999;
}
/* Preload aniamtion */
.preloader {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /*background: #fff;*/
    text-align: center;
  }
  
  .loading {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    height: 80px;
    margin: auto;
  }
  
  .animationLogo {
    overflow: hidden;
    vertical-align: middle;
  }
  
  .progress-wrapper {
    width: 90%;
    height: 2px;
    border-radius: 2px;
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    display: block;
    margin: auto;
    overflow: hidden;
    margin-top: 14px;
  }
  
  .progress {
    display: block;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 200px;
    height: 2px;
    left: -102px;
    background: radial-gradient(46.66% 46.66% at 49.32% 43.12%, #00C0F3 0%, #02BBEF 15%, #07ABE4 33%, #0F92D1 53%, #1A6FB7 74%, #284296 96%, #2B3990 100%);
    -webkit-animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    animation: loader-anim 1s 0s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  @-webkit-keyframes loader-anim {
    0% {
        left: -102px;
    }
  
    100% {
        left: 102px;
    }
  }
  
  @-moz-keyframes loader-anim {
    0% {
        left: -102px;
    }
  
    100% {
        left: 102px;
    }
  }
  
  @keyframes loader-anim {
    0% {
        left: -102px;
    }
  
    100% {
        left: 102px;
    }
  }
.dropdownContainer{
    position: relative;
}

.dropdwonLabel{
    padding: 15px;
    cursor: pointer;
    background-color: #272b36;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 220px;
}

.dropdownSelected{
    font-size: 16px;
    padding-left: 10px;
}

.arrowDownIcon {
    margin-left: 25px;
    position: absolute;
    right: 10px;
}

.arrowUpIcon {
    margin-left: 25px;
    transform: rotate(180deg);
    position: absolute;
    right: 10px;
}

.optionSelection{
    position: absolute;
    background-color: #272b36;
    border-radius: 10px;
    width: 100%;
    height: auto;
    z-index: 99;
    top: 70px;
}

.shadowBox{
    box-shadow: 1px 1px 6px #ccc;
}

.optionItems{
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 10px;
}

.calendarIcom{
    width: 25px;
}

@media only screen and (max-width: 767px) {
    .optionItems{
        font-size: 14px;
        padding: 6px 15px;
    }
}

@media only screen and (min-width: 768px) {
    .optionItems:hover {
        background-color: #1f2129;
        font-size: 20px;
    }
}
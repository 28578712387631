.orderSearchHolder {
    position: relative;
    display: inline;
}

.orderSearchIcon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
}

.orderSearchHolder .orderSearch {
    width: 320px;
    background-color: #272b36;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    margin-right: 10px;
    height: 100%;
    font-size: 18px;
}

.orderSearchHolder input {
    background-color: #272b36;
    color: #ffffff;
    font-size: 18px;
}

.orderHistoryTable {
    /*min-height: 455px;*/

    /*
    display: flex;
    flex-direction: column;
    justify-content: center;
    */
}

.orderHistoryTable div,
.orderHistoryTable th {
    background-color: #232732 !important;
}

.orderItemIcon {
    cursor: pointer;
}

.orderItemIcon:hover {
    fill: #0D9FFF;
}

.tableProductIcon {
    width: 50px;
    background-color: #ffffff;
    border-radius: 10px;
}

.tableProductTitle {
    padding-left: 10px;
}

.orderStatisticDetails {
    /*padding: 25px;*/

    padding: 8px 25px 0px 25px;
}

.detailsBack {
    color: #0D9FFF;
    font-weight: bold;
    display: flex;
    cursor: pointer;
    padding-bottom: 8px;
}

.orderDetailsTop {
    display: flex;
    justify-content: space-between;
}

.orderDetailsImg img {
    width: 80px;
    background-color: #ffffff;
    border-radius: 10px;
}

.orderDetailsLeft,
.orderDetailsRight {
    width: 48%;
}

.orderDetailsLeft {
    display: flex;
}

.orderDetailsRight {
    padding: 0px;
}

.orderDetailsContent {
    padding-left: 10px;
}

.orderDetailCard {
    padding: 20px !important;
}

.orderHistoryTable .ant-pagination {
    padding-right: 10px !important;
}

.tableColumnProduct {
    display: flex;
    align-items: center;
}

.orderHistorySpinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.orderHistorySpinner .ant-spin-lg .ant-spin-dot {
    font-size: 80px !important;
}

.orderHistorySpinner .ant-spin-lg .ant-spin-dot i {
    width: 30px !important;
    height: 30px !important;
}

#userOrderTotal .ant-table-row {
    height: 72px;
}

#userOrderSingle .ant-table-row {
    height: 45px;
}

.ant-pagination-item {
    border-radius: 50% !important;
    text-align: center !important;
    font-family: 'Inter', sans-serif !important;
    border: none !important;
    margin-right: 3px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.ant-pagination-item a {
    color: #fff !important;
}

.ant-pagination-item-active {
    background-color: #0D9FFF !important;
    color: #fff !important;
}

.ant-pagination-item-active .ant-pagination-item-link {
    color: #fff !important;
}

.ant-pagination-next {
    margin-left: 5px !important;
}

.ant-pagination-item-link {
    border: none !important;
}

@media only screen and (max-width: 767px) {

    .contentName,
    .contentColor,
    .contentPrice,
    .selectedDateInnerLabel,
    .selectedDateInnerAmount {
        font-size: 12px;
    }

    .orderDetailsTop {
        flex-direction: column;
    }

    .orderDetailsLeft {
        margin-bottom: 20px;
    }

    .orderDetailsLeft,
    .orderDetailsRight {
        width: 100% !important;
    }
}

@media only screen and (min-width: 768px) {
    .orderHistoryContainer {
        /*height: calc(100% - 264px);*/
        height: 60%;
    }
}

.orderHistoryTable .ant-table-tbody>tr.ant-table-placeholder:hover>td div {
    background: #141414 !important;
}

.itemSpent {
    color: #FF9E2D;
}

/*added no hover*/
.ant-table-tbody>tr.ant-table-row:hover>td {
    background: none !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 11px 11px !important;
}
.updateCardTitle{
  display: flex;
  align-items: baseline;
  font-size: 24px;
}

.updateCardSubTitle{
  padding-left: 5px;
  color: #bdbac4;
  font-size: 16px;
}

.updatePaymentRoot{
  /* max-width: 500px; */
  /* margin: 35vh auto; */
}

.updatePaymentContent{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.btnContainer{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
}

.paymentBtn{
  margin: '2em auto 1em';
}

.stripCardInputSection  > div:nth-child(2) {
  padding: 15px 10px;
  background-color: #272b36;
  border-radius: 10px;
}

.updateCardDesc{
  padding-top: 10px;
  color: #bdbac4;
  font-size: 14px;
}

.updateCardFooter{
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
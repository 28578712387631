.orderSearchHolder {
    position: relative;
    display: inline;
}

.orderSearchIcon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
}

.orderSearchHolder .orderSearch {
    width: 320px;
    background-color: #272b36;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    margin-right: 10px;
    height: 100%;
    font-size: 18px;
}

.orderSearchHolder input{
    background-color: #272b36;
    color: #ffffff;
    font-size: 18px;
}

.orderHistoryTable div,
.orderHistoryTable th {
    background-color: #232732 !important;
}

.orderItemIcon{
    cursor: pointer;
}

.tableProductIcon{
    width: 50px;
    background-color: #ffffff;
    border-radius: 10px;
}

.tableProductTitle{
    padding-left: 10px;
}

.orderStatisticDetails{
    /*padding: 25px;*/

    padding: 8px 25px 0px 25px;
}

.detailsBack{
    color: #0D9FFF;
    font-weight: bold;
    display: flex;
    cursor: pointer;
    padding-bottom: 8px;
}

.orderDetailsTop{
    display: flex;
    justify-content: space-between;

    height: 110px;
}

.orderDetailsImg img{
    width: 80px;
    background-color: #ffffff;
    border-radius: 10px;
}

.orderDetailsLeft,
.orderDetailsRight{
    width: 48%;
}

.orderDetailsLeft{
    display: flex;
}

.orderDetailsRight{
    padding: 0px;
}

.orderDetailsContent{
    padding-left: 10px;
}

.orderDetailCard{
    padding: 20px !important;
}

.orderHistoryTable .ant-pagination{
    padding-right: 10px !important;
}

.tableColumnProduct{
    display: flex;
    align-items: center;
}

.orderHistorySpinner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
}

.orderHistorySpinner .ant-spin-lg .ant-spin-dot{
    font-size: 80px !important;
}
  
.orderHistorySpinner .ant-spin-lg .ant-spin-dot i{
    width: 30px !important;
    height: 30px !important;
}
.calendarContainer>div:nth-child(2) {
  background-color: #232732;
  padding: 0px 20px;
}

.calendarContainer>div:nth-child(2)>div {
  background-color: #232732 !important;
}

.calendarContainer ant-select-selector {
  background-color: #232732;
}


.upcomingDropsContainer ant-select-selector {
  background-color: #232732;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  min-width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
  background-color: #0D9FFF !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
  border: 1px solid #0D9FFF !important;
}

.ant-picker-calendar-date-today,
.ant-picker-calendar-date-today.ant-picker-calendar-date-today {
  border: none !important;
}

.ant-picker-cell {
  min-width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
}

.ant-picker-cell-inner {
  min-width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  /*border-radius: 15px !important;*/
  border-radius: 50% !important;
}

.ant-picker-calendar-date-today::before {
  content: '';
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 4px;
}

.listTable {
  list-style-type: none;
  position: absolute;
  
  right: 22%;
  top: 35%;
  
}

/* .ant-picker-cell-selected .listTable {
  right: 2%;
} */

.cellItem span span {
  background-color: #0D9FFF;
}

.ant-picker-cell-selected .cellItem span span {
  background-color: white !important;
}

.productItemTitle,
.productItemSku,
.productItemPrice {
  line-height: 15px;
}

.ant-picker-calendar-header div:nth-child(3) {
  display: none;
}

.selectedDate {
  padding: 0px 40px !important;
  margin-bottom: 20px;
}

.selectedDateInner {
  background-color: #272b36 !important;
  padding: 10px 20px !important;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.selectedDateInnerAmount {
  font-weight: 500;
}

@media only screen and (min-width: 768px) {
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 8px 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .selectedDate {
    padding: 0px !important;
  }
}

.calenderHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 23px;
}

.calenderIcon {
  padding: 5px;
  cursor: pointer;
  width: 40px;
}

.rotateCalenderIcon {
  transform: rotate(180deg);
  width: 40px;
}

.releaseCalenderContainer {
  height: 100%;
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  position: relative;
}

.dateText {
  font-size: 18px;
  color: #ffffff;
}

.productBody {
  padding-bottom: 3px;
  height: 330px;
  overflow-y: auto;
}
.container{
  margin: 0px auto;
  width: 100%;
  /*height: calc(100% - 108px);*/
  height: 900px;
  background-color: #1f2129;
  display: flex;
  justify-content: space-between;
  /* grid-column-gap: 1%; */
  color: #ffffff;
}

.containerLeft{
  width: 66%;
  grid-row-gap: 20px;
  display: flex;
  flex-direction: column;
}

.containerRight{
  /* width: 427px; */
  width: 33%;
}

.dashboardSpinner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardSpinner .ant-spin-lg .ant-spin-dot{
  font-size: 80px !important;
}

.dashboardSpinner .ant-spin-lg .ant-spin-dot i{
  width: 30px !important;
  height: 30px !important;
}

.dashboardContainer{
  /*max-width: 1200px;*/
  max-width: 1400px;
  margin: auto;
  /*padding-bottom: 32px;*/
}

.backgroundBlur{
  filter: blur(2px);
}

@media only screen and (max-width: 767px) {
  .container{
    flex-direction: column;
  }

  .containerLeft,
  .containerRight{
    width: 100%;
  }

  .width100 button{
    width: calc(100% - 40px) !important;
    margin: 20px;
    font-size: 14px !important;
    font-weight: 500;
  }

  .calendarMobileMenu{
    position: absolute;
    top: 160px;
    z-index: 99;
    width: 100%;
    background-color: rgba(31,33,41, 0.9);
    padding: 10px;
  }
}

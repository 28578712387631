.pop {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.cardPlaceholder {
  width: 100%;
}

.cardBox {
  position: relative;
}

.cardLast4 {
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
}

.cardType {
  position: absolute;
  bottom: 50px;
  right: 50px;
  bottom: 30px;
  right: 30px;
  width: 80px;
}

.cardLabel {
  padding: 0px 32px;
  font-size: 18px;
  color: #ffffff;
  position: relative;
}

.cardContent {
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;
}

.cardAddress {
  color: #bdbac4;
  padding: 0px 32px;
  font-size: 16px;
}

.cardContentHolder {
  padding-bottom: 10px;
}

.cardContentLeft {
  font-size: 16px;
  color: #bdbac4;
}

.cardContentRight {
  font-size: 16px;
  color: #ffffff;
}

.cardBtnContainer {
  padding: 0px 32px;
  margin-top: 90px;
}

.cardBtn {
  color: #0D9FFF !important;
  background-color: #272b36 !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  width: 100% !important;
  border-radius: 12px !important;
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 767px) {
  .cardLast4 {
    font-size: 14px;
  }

  .cardType {
    width: 60px;
  }

  .cardLabel {
    font-size: 14px;
    padding: 0px 20px;
  }

  .cardContent,
  .cardAddress {
    padding: 0px 20px;
  }

  .cardContentLeft,
  .cardContentRight,
  .cardAddress {
    font-size: 12px;
  }

  .cardBtnContainer {
    padding: 0px 20px;
    margin-top: 32px;
  }

  .cardBtnContainer button {
    font-size: 14px !important;
  }
}

.cardInfoContainer {
  height: 100%;
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  position: relative;
}
.planCard{
    width: 25%;
    background-color: #232732;
    border-radius: 10px;
    padding: 24px;
    position: relative;
    height: 470px;
}

.planCard:hover .planCardBtn{
    color: #ffffff !important;
    background-color: #0D9FFF !important;
}

.mostPopular{
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    color: #FF9E2D;
    background-color: rgb(255,158,45, 0.1);
    padding: 2px 15px;
    border-radius: 10px;
}

.planIconContainer{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.planIcon{
    width: 80px;
}

.planTitle{
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    font-weight: bold;
}

.planDesc{
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #bdbac4;
    color: #bdbac4;
    font-size: 14px;
}

.planPrice{
    text-align: center;
    font-size: 36px;
    padding: 20px 0px;
    color: #ffffff;
    font-weight: bold;
}

.planPrevPrice{
    text-align: center;
    color: #bdbac4;
    font-size: 14px;
    text-decoration: line-through;
}

.planDiscountRate{
    text-align: center;
    font-size: 14px;
    color: #1ec288;
    font-weight: bold;
}

.planBtn{
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
}

.planCardBtn{
    color: #0D9FFF !important;
    background-color: #272b36 !important;
    border: none !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 0px !important;
    height: 50px !important;
    width: 100% !important;
    border-radius: 12px !important;
}

@media only screen and (max-width: 767px) {
    .marketPlaceHeader{
        font-size: 24px;
    }

    .planCardSpace{
        margin-top: 30px;
    }

    .planTitle{
        font-size: 18px;
    }

    .planDesc{
        font-size: 12px;
    }

    .planCardBtn{
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 768px) {
    .planCard:hover{
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        box-shadow: 1px 1px 6px #ccc;
    }
}

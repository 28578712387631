.headerOutContainer{
    max-width: 1200px;
    margin: auto;
}

.headerContainer{
    position: relative;
}

.headerRightSection{
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
}

.headerName{
    font-weight: 500;
}

.adminArrowUpIcon{
    margin-left: 10px;
    transform: rotate(180deg);
}

.adminArrowDownIcon{
    margin-left: 10px;
}
.productCards {
    display: grid;
    
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));

     
}

.pagi {
    display: flex;
    justify-content: end;
    margin-top: 1px;
}

.headerProducts {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.refreshIcon {
    display: flex;
    justify-content: center;
    color: #0D9FFF !important;
    background-color: #272b36 !important;
    height: 45px !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 10px 15px !important;
    border-radius: 12px !important;
    margin-right: 5px;
    cursor: pointer;
}
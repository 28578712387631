.customSelect {
    
    display: block !important;
    box-sizing: border-box !important;
    width: 100%;
    border-radius: 10px;
    border: none !important;
    color: #ffffff !important;
    
    background-color: #272b36;
    padding: 5px 10px !important;
    margin-bottom: 15px !important;
    font-size: 14px !important;
    
}


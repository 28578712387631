.impressumContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1f2129;
    padding-top: 120px;
    overflow: hidden;
}

.textContainer{
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}
.orderStatisticBody{
  padding: 0px 32px 32px;
  display: flex;
  justify-content: space-between;
  grid-column-gap: 2%;
}

.orderStatisticItem{
  width: 50%;
  display: flex;
  align-items: center;
}

.statisticItemLeft{
  width: 40%;
}

.statisticItemLeftTop{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.statisticItemMid{
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.statisticItemLabel{
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
}

.statisticItemIcom{
  width: 40px;
}

.statisticItemMidData{
  font-size: 44px;
  font-weight: bold;
}

.statisticItemMidUnit{
  font-size: 18px;
  font-weight: bold;
  color: #bdbac4;
  padding-left: 10px;
}

.statisticItemBottom{
  font-size: 18px;
  font-weight: 500;
}

.colorGreen {
  color: #1ec288;
}

.colorRed{
  color: #eb3f35;
}

.statisticChart{
  width: 60%;
  height: 100%;
}

.statisticChart > div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderStatisticTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}

.orderStatisticHeader{
  padding: 0 !important;
}

.orderStatisticSelection{
  display: flex;
}

@media only screen and (max-width: 767px) {
  .orderStatisticTop{
    align-items: start;
    padding: 20px;
    flex-direction: column;
  }

  .orderStatisticSelection{
    padding-top: 10px;
    flex-direction: column;
    width: 100%;
  }

  .orderSearchHolder .orderSearch{
    margin-right: 0px !important;
    width: 100% !important;
  }

  .dropdwonLabel{
    padding: 12px;
    width: 100%;
  }

  .dropdownSelected,
  .statisticItemMidUnit,
  .statisticItemLabel{
    font-size: 14px;
  }

  .orderStatisticBody{
    flex-direction: column;
    padding: 0px 24px 20px;
  }

  .orderStatisticItem{
    width: 100%;
  }

  .statisticItemMidData{
    font-size: 32px;
  }

  .desktopView{
    display: none;
  }

  .statisticItemLeft,
  .statisticChart{
    width: 50%;
  }

  .orderSearchHolder{
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .mobileView{
    display: none;
  }

  .orderStatisticContainer{
    /*height: 264px;*/
    height: 40%;
  }  

  .orderStatisticTop{
    height: 122px;
  }

  .orderStatisticBody{
    height: calc(100% - 122px);
  }
}
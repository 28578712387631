.paymentHeader{
  border-bottom: 1px solid #303030;
}

.paymentBody{
  padding-bottom: 32px;
  /*height: 300px;*/
  height: 400px;
  overflow-y: auto;
}

.paymentInfoContainer {
  height: 60%;
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  position: relative;
}

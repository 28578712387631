.marketPlaceContainer{
    background-color: #1f2129;
    padding-top: 120px;
}

.marketPlaceHeader{
    padding: 40px 0px 10px;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
}

.marketPlanText{
    color: #0D9FFF;
}

.marketPlaceDesc{
    color: #bdbac4;
    text-align: center;
}

.planContainer{
    display: flex;
    grid-column-gap: 2%;
    max-width: 1024px;
    margin: auto;
    padding: 80px 0px 40px;
}

@media only screen and (max-width: 767px) {
    .marketPlaceHeader{
        font-size: 24px;
    }

    .marketPlaceDesc{
        font-size: 12px;
    }

    .planContainer{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 40px 0px 20px;
    }

    .planCard{
        width: 80%;
    }
}
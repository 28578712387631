
.form {
    max-width: 800px;
    margin: 0 auto;
    /*font-family: sans-serif;*/
  }

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }

input[type="submit"],
.button {
    background: #ec5990;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 20px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
    display: block;
    appearance: none;
    border-radius: 10px;
    width: 100%;
    font-weight: lighter;
  }
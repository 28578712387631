.infoContainer{
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  /*height: 100%;*/
  position: relative;
}

.infoHeader{
  font-size: 24px;
  color: #ffffff;
  padding: 32px;
  font-weight: 500;
}

.infoLabel{
  font-size: 16px;
  color: #bdbac4;
}

.infoContent{
  font-size: 16px;
  color: #ffffff;
  position: relative;
}

.infoBody{
  display: flex;
  flex-direction: column;
  padding: 0px 32px 24px;
}

.infoBodyColumn{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.infoBodyItem{
  width: 48%;
}

.statusActive{
  color: #1ec288;
  background-color: #223339;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 15px;
}

.statusDisable{
  color: #EB3F35;
  background-color: #372932;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 15px;
}

.keyCta{
  color: #0D9FFF;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}

.infoBtnContainer{
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 20px;
}

.deactiveBtn{
  color: #ffffff !important;
  background-color: #272b36 !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  border-radius: 12px !important;
}

.infoThreeD {
  width: 28px;
  margin-left: 8px;
  cursor: pointer;
}

.infoPopup {
  position: absolute;
  z-index: 99;
  background-color: #232732;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 6px #ccc;
  max-width: 400px;
  bottom: 40px;
  left: 150px;
}

.cancelBtn{
  color: #eb3f35 !important;
  background-color: #272b36 !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 60px !important;
  height: 50px !important;
  border-radius: 12px !important;
}

@media only screen and (max-width: 767px) {
  .infoHeader{
    font-size: 18px;
    padding: 20px;
    font-weight: 500;
  }

  .infoContent{
    font-size: 14px;
  }

  .infoLabel{
    font-size: 12px;
  }

  .infoBody{
    padding: 0px 20px 18px;
  }

  .infoBodyColumn{
    flex-direction: column;
  }

  .infoBodyItem{
    width: 100%;
  }

  .infoBtnContainer{
    flex-direction: column;
  }

  .infoBtnContainer button:first-child{
    margin-bottom: 10px !important;
  }

  .infoBtnContainer button{
    margin: 0px !important;
    font-size: 14px !important;
  }
}

.licenseDetailContainer {
  background-color: #232732;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  height: 40%;
  position: relative;
}
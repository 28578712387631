.globalHeadersContainer{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    max-width: 1240px;
    width: 84%;
    height: auto;
    background: rgba(43, 47, 58, 0.4);
    backdrop-filter: blur(32px);
    border-radius: 16px;
    padding: 0px !important;
    margin: auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.globalHeaderLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-left: 16px;
}

.globalHeaderBtnGroup{
    display: flex;
}

.globalHeaderLabel{
    color: #ffffff;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
}

.globalHeaderLogo img{
    width: 30px;
    height: 30px;
}

.marketplaceBtn {
    background-color: #232730 !important;
    color: #0d9fff !important;
}